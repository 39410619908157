@let isTablet = isTablet$ | async;
@let isHandset = isHandset$ | async;
@let isOpen = isOpen$ | async;
@let isScrolled = isScrolled$ | async;
@let showedToolbar = showedToolbar$ | async;
<mat-sidenav-container>
  <mat-sidenav
    #drawer
    fixedInViewport="true"
    [autoFocus]="false"
    [mode]="isHandset ? 'over' : ( isTablet && isOpen ? 'over' : 'side')"
    [opened]="isOpen"
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    (openedChange)="open($event)"
    [ngClass]="{
      rail: isTablet,
      'rail-closed': isTablet && !drawer.opened,
      'rail-opened': isTablet && drawer.opened
      }"
  >
    @defer(when isOpen; on idle) {
    <app-navbar [sidenav]="drawer" />
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <app-toolbar
      [ngClass]="{'is-scrolled': isScrolled}"
      [@scrollUpAnimation]="isScrolled === false || showedToolbar"
    />
    <main
      [@contextChange]="o | state"
      [style.viewTransitionName]="viewTransitionName()"
    >
      <router-outlet #o="outlet" />
    </main>

    <div
      class="bottom"
      [@scrollDownAnimation]="showedToolbar"
    >
      @defer(when isHandset) {
      <app-bottom [visibility]="showedSpeedDialSignal()" />
      }
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
