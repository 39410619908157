@if (((matchday$ | async)?.number|| 0) > 1) {
<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 grid-rows-1 gap-4 md:gap-6 lg:gap-8 items-start">
  @for (role of roles; track role.role.id) {

  <mat-card appearance="outlined">
    <app-player-image
      mat-card-image
      inCard
      [member]="role.best_players?.first"
    />
    @if (!role.best_players) {
    <mat-progress-bar mode="indeterminate" />
    }
    <mat-card-header>
      <mat-card-title>
        @if (role.best_players) {
        <span class="mat-text-primary">
          {{role.best_players.first.ratings[0]?.points | number}}
        </span>
        {{role.best_players.first.player.full_name}}
        } @else {
        <span class="mat-text-primary">-</span>
        }
      </mat-card-title>
      <mat-card-subtitle>{{role.role.singolar}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      @defer {
      @if(role.best_players) {
      <app-best-players-list [best_players]="role.best_players" />
      }
      }
    </mat-card-content>
  </mat-card>

  }
</div>
} @else {

<app-mat-empty-state
  description="Buona asta!"
  icon="wb_sunny"
  label="La stagione non è ancora iniziata"
  rounded
/>

}
